import React, { useEffect, useRef, useState, FC } from 'react';
import { StyledSearchCloseButton, StyledSearchInputDesktop } from '../N20MainMenu/styled';
import { useTranslation } from '~/shared/utils/translation';
import { useSearchStore } from '~/services/search/useSearchStore';
import { useRouter } from 'next/router';
import { useDebounce, useKeyPressEvent } from 'react-use';
import {
    useHits,
    useSearchBox,
    UseSearchBoxProps,
    useConfigure,
    useQueryRules,
} from 'react-instantsearch';
import { sanitizeStringForUrl, buildCategoryPath, findCategoryMatch } from './search-utils';
import { useFrame } from '~/shared/utils';
import { useEvents } from '~/shared/hooks/useEvents';
import type { PrimaryCategory } from './search.definitions';
import CloseIcon from '~/icons/close.svg';
import { SearchPlaceholderComponent } from './SearchPlaceholderComponent';

type ISearchBoxProps = UseSearchBoxProps & {
    onSubmitSearch: (searchQuery: string, redirectUrl?: string) => void;
};

type Redirect = {
    name?: string;
    url?: string;
};

type RenderingContent = {
    redirect?: Redirect;
};

type SearchResults = {
    renderingContent?: RenderingContent;
};

const queryHook: UseSearchBoxProps['queryHook'] = (query, search) => {
    if (query && query.length >= 3) {
        search(query);
    }
};

export const SearchInput: FC<ISearchBoxProps> = ({ onSubmitSearch }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState('');
    const { query: params } = useRouter();
    const { translate } = useTranslation();
    const { data } = useFrame();
    const { overlaySearchResultEvent } = useEvents(data);
    const { setShowSearch, showSearchPage } = useSearchStore();

    useConfigure({ hitsPerPage: 6 });
    const { hits, results } = useHits();
    const { refine, clear } = useSearchBox({ queryHook });
    const { items: customRuleData } = useQueryRules();

    useEffect(() => {
        if (!showSearchPage) {
            setQuery('');
            setShowSearch(false);
        }
    }, [showSearchPage]);

    useKeyPressEvent('Escape', () => {
        clear();
        handleClose();
    });

    useKeyPressEvent('Enter', () => {
        if (!query || inputRef.current !== document.activeElement) {
            return;
        }

        const formattedQuery = sanitizeStringForUrl(query);

        const redirectName = customRuleData?.[0]?.renderingContent?.redirect?.name;
        const redirectUrl = customRuleData?.[0]?.renderingContent?.redirect?.url;

        //TODO assertions will be fixed in IMC-514
        const primaryCategory = hits[0]?.__primary_category as PrimaryCategory;

        //TODO assertions will be fixed in IMC-514
        let finalUrl = (results as SearchResults)?.renderingContent?.redirect?.url || '';

        if (redirectName === 'brands') {
            finalUrl = `${redirectUrl}${formattedQuery}`;
        } else if (redirectName === 'categories' && primaryCategory) {
            const categoryMatch = findCategoryMatch(primaryCategory, query);
            if (categoryMatch) {
                const categoryPath = buildCategoryPath(categoryMatch);
                finalUrl = `${redirectUrl}${categoryPath}`;
            }
        }

        inputRef?.current?.blur();
        clear();
        onSubmitSearch(query, finalUrl);

        overlaySearchResultEvent({
            search_term: query,
            number_of_results: results?.nbHits,
            search_url: window.location.href,
        });
    });

    useDebounce(() => refine(query), 200, [query]);
    useDebounce(
        () => {
            setTimeout(() => {
                overlaySearchResultEvent({
                    search_term: query,
                    number_of_results: results?.nbHits,
                    search_url: window.location.href,
                });
            }, 500);
        },
        1000,
        [query],
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;
        if (!newQuery) {
            clearInput();
            return;
        }
        setQuery(newQuery);
    };

    const clearInput = () => {
        setQuery('');
        setTimeout(() => inputRef?.current?.focus());
    };

    const handleClose = () => {
        setShowSearch(false);
        setTimeout(() => inputRef?.current?.blur());
    };

    return (
        <>
            <SearchPlaceholderComponent
                icon={showSearchPage ? 'arrowLeft' : 'search'}
                onClick={handleClose}
            >
                {!showSearchPage && translate('header.search')}
            </SearchPlaceholderComponent>

            <StyledSearchInputDesktop
                aria-label="Search"
                value={query}
                defaultValue={params.query}
                onFocus={() => setShowSearch(true)}
                onChange={handleChange}
                withLabel={false}
                ref={inputRef}
                data-testid="search-input"
            />
            {query && (
                <StyledSearchCloseButton
                    aria-label="Close"
                    size="small"
                    onClick={clearInput}
                    variant="plain"
                >
                    <CloseIcon />
                </StyledSearchCloseButton>
            )}
        </>
    );
};
